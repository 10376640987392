import { useEffect } from 'react';
import { Button } from '@vitalsource/vstui';
import useAnalytics from '../../utils/useAnalytics';
import useThirdPartyCookiesEnabled from '../../utils/use3rdPartyCookies';
import { ExternalWindow } from '@vitalsource/vstui-icons';
import styled from 'styled-components';
import { useKey } from '../../BrandProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import FooterButtons from '../../pages/FooterButtons';
import { PageTitle } from '../../utils/PageTitle';
import PageViewFrame from '../page-view-container/PageViewFrame';

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Content: styled.div`
    max-width: 494px;
    padding: 12px;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  MainMessage: styled.h1`
    text-align: center;
    font-weight: 500;
    font-size: 2.4rem;
    margin: 0;
    padding: 0 0 0px 0;
  `,
  SupportMessage: styled.p`
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
    padding: 0 0 12px 0;
    margin-top: 24px;
  `,
  ContactSupport: styled.p`
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
  `,
  ButtonContainer: styled.div`
    width: 240px;
    margin-top: 24px;
  `,
};

const FrameCheck = () => {
  const { trackEvent } = useAnalytics();
  const intl = useIntl();
  const thirdPartyCookiesEnabled = useThirdPartyCookiesEnabled();
  const autoredirect = useKey('EmbeddedChoice.autoRedirectIfPossible', false);
  const supportEnabled = useKey('Support.enabled', true);
  const supportUrl = useKey('Support.url', 'https://support.vitalsource.com/');

  const newUrl = new URL(window.location.href);
  newUrl.pathname = '/redirect';
  newUrl.searchParams.set('skip_frame', 'true');
  const redirectUrl = new URL(window.location.href);
  if (redirectUrl.pathname === '/frame-check') {
    redirectUrl.pathname = '/redirect';
  }

  useEffect(() => {
    trackEvent('frame_check_status', {
      can_continue: !!thirdPartyCookiesEnabled,
    });
  }, []);

  useEffect(() => {
    if (autoredirect && thirdPartyCookiesEnabled) {
      window.location.href = newUrl.toString();
    }
  }, [autoredirect]);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: 'frame-check.main-message',
          defaultMessage: 'This page needs to open in a new tab',
        })}
      />
      <PageViewFrame>
        <Styled.Wrapper>
          <Styled.Content>
            <Styled.MainMessage>
              <FormattedMessage
                id="frame-check.main-message"
                defaultMessage="This page needs to open in a new tab"
              />
            </Styled.MainMessage>

            <Styled.SupportMessage>
              <FormattedMessage
                id="frame-check.support-message"
                defaultMessage="For your security and privacy, your course materials dashboard will that other websites can't access your personal information."
              />
            </Styled.SupportMessage>
            {supportEnabled && (
              <Styled.ContactSupport>
                <FormattedMessage
                  id="frame-check.contact-support"
                  defaultMessage="If you need help accessing your dashboard, please {contact support}."
                  values={{
                    a: (chunks) => (
                      <Button
                        href={supportUrl}
                        inline
                        onClick={() => {
                          trackEvent('frace_check_contact-support', {
                            can_continue: !!thirdPartyCookiesEnabled,
                          });
                        }}
                      >
                        {chunks}
                      </Button>
                    ),
                  }}
                />
              </Styled.ContactSupport>
            )}
            <Styled.ButtonContainer>
              <Button
                href={redirectUrl.toString()}
                target="_blank"
                onClick={() => {
                  trackEvent('frame_check_new-window', {
                    can_continue: !!thirdPartyCookiesEnabled,
                  });
                }}
                iconStart={<ExternalWindow />}
                pill
                fullWidth
              >
                <FormattedMessage
                  id="frame-check.open-tab"
                  defaultMessage="Open in New Tab"
                />
              </Button>
            </Styled.ButtonContainer>
          </Styled.Content>
          <FooterButtons />
        </Styled.Wrapper>
      </PageViewFrame>
    </>
  );
};

export default FrameCheck;
